import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './DataAnalysisPage.css'; // Импорт стилей

const DataAnalysisPage = () => {
    const [data, setData] = useState([]); // Состояние для хранения данных
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');

    useEffect(() => {
        // Получение данных из базы данных
        const fetchData = async () => {
            try {
                const response = await axios.get("http://94.103.86.215:4000/database");
                setData(response.data); // Установка полученных данных в состояние
                console.log("Fetched data:", response.data); // Debugging
            } catch (error) {
                console.error("Ошибка при получении данных:", error.message);
            }
        };
        fetchData(); // Вызов функции получения данных
    }, []);

    // Функция для фильтрации и вычисления статистики
    const calculateStatistics = () => {
        const filteredData = data.filter(item => {
            const itemDate = new Date(item.timestamp);
            const startDate = new Date(selectedStartDate);
            const endDate = new Date(selectedEndDate);
            return itemDate >= startDate && itemDate <= endDate;
        });

        console.log("Filtered data:", filteredData); // Debugging

        if (filteredData.length === 0) return {}; // Если нет данных, возвращаем пустой объект

        const temperatures = filteredData.map(item => item.temperature);
        const humidities = filteredData.map(item => item.humidity);
        const pressures = filteredData.map(item => item.pressure);
        const co2Levels = filteredData.map(item => item.co2);
        const otherGases = filteredData.map(item => item.azot);
        const lights = filteredData.map(item => item.light);

        const calculateAverage = (arr) => (arr.length > 0 ? (arr.reduce((sum, value) => sum + value, 0) / arr.length).toFixed(2) : 0);

        return {
            maxTemperature: temperatures.length > 0 ? Math.max(...temperatures) : 0,
            minTemperature: temperatures.length > 0 ? Math.min(...temperatures) : 0,
            avgTemperature: calculateAverage(temperatures),
            maxHumidity: humidities.length > 0 ? Math.max(...humidities) : 0,
            minHumidity: humidities.length > 0 ? Math.min(...humidities) : 0,
            avgHumidity: calculateAverage(humidities),
            maxPressure: pressures.length > 0 ? Math.max(...pressures) : 0,
            minPressure: pressures.length > 0 ? Math.min(...pressures) : 0,
            avgPressure: calculateAverage(pressures),
            maxCo2: co2Levels.length > 0 ? Math.max(...co2Levels) : 0,
            minCo2: co2Levels.length > 0 ? Math.min(...co2Levels) : 0,
            avgCo2: calculateAverage(co2Levels),
            maxOtherGas: otherGases.length > 0 ? Math.max(...otherGases) : 0,
            minOtherGas: otherGases.length > 0 ? Math.min(...otherGases) : 0,
            avgOtherGas: calculateAverage(otherGases),
            maxLight: lights.length > 0 ? Math.max(...lights) : 0,
            minLight: lights.length > 0 ? Math.min(...lights) : 0,
            avgLight: calculateAverage(lights),
        };
    };

    const stats = calculateStatistics();

    return (
        <div>
            <h1>Анализ данных</h1>
        
            <input 
                type="date" 
                value={selectedStartDate} 
                onChange={(e) => setSelectedStartDate(e.target.value)} 
            />
            <input 
                type="date" 
                value={selectedEndDate} 
                onChange={(e) => setSelectedEndDate(e.target.value)} 
            />
            <table className="table">
                <thead>
                    <tr>
                        <th>Параметр</th>
                        <th>Макс</th>
                        <th>Мин</th>
                        <th>Среднее</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Температура</td>
                        <td>{stats.maxTemperature}</td>
                        <td>{stats.minTemperature}</td>
                        <td>{stats.avgTemperature}</td>
                    </tr>
                    <tr>
                        <td>Влажность</td>
                        <td>{stats.maxHumidity}</td>
                        <td>{stats.minHumidity}</td>
                        <td>{stats.avgHumidity}</td>
                    </tr>
                    <tr>
                        <td>Давление</td>
                        <td>{stats.maxPressure}</td>
                        <td>{stats.minPressure}</td>
                        <td>{stats.avgPressure}</td>
                    </tr>
                    <tr>
                        <td>CO2</td>
                        <td>{stats.maxCo2}</td>
                        <td>{stats.minCo2}</td>
                        <td>{stats.avgCo2}</td>
                    </tr>
                    <tr>
                        <td>Другие газы</td>
                        <td>{stats.maxOtherGas}</td>
                        <td>{stats.minOtherGas}</td>
                        <td>{stats.avgOtherGas}</td>
                    </tr>
                    <tr>
                        <td>Свет</td>
                        <td>{stats.maxLight}</td>
                        <td>{stats.minLight}</td>
                        <td>{stats.avgLight}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default DataAnalysisPage;
