import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as THREE from 'three';
import './Graph3DPage.css';

const Graph3DPage = () => {
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [xAxis, setXAxis] = useState('temperature');
    const [yAxis, setYAxis] = useState('humidity');
    const [zAxis, setZAxis] = useState('pressure');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [renderer, setRenderer] = useState(null);
    const [scene, setScene] = useState(null);
    const [camera, setCamera] = useState(null);

    useEffect(() => {
        // Fetch data from database
        axios.get("http://94.103.86.215:4000/database")
            .then(response => {
                setData(response.data);
                console.log("Fetched data:", response.data); // Debugging
            })
            .catch(error => {
                console.error("Ошибка при получении данных:", error.message);
            });
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            // Filter data based on selected date range
            const filteredData = data.filter(item => {
                const itemDate = new Date(item.timestamp); // Assuming you have a timestamp field
                const start = new Date(startDate);
                const end = new Date(endDate);
                return itemDate >= start && itemDate <= end;
            });
            setSelectedData(filteredData);
            console.log("Filtered data:", filteredData); // Debugging
        }
    }, [data, startDate, endDate]);

    useEffect(() => {
        // Initialize 3D scene only once
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer();

        if (!renderer) {
            console.error("WebGL not supported.");
            return;
        }

        renderer.setSize(window.innerWidth, window.innerHeight);
        document.getElementById('3d-graph').innerHTML = ''; // Clear previous render
        document.getElementById('3d-graph').appendChild(renderer.domElement);

        // Add light to the scene
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5); // Soft white light
        scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
        directionalLight.position.set(1, 1, 1).normalize();
        scene.add(directionalLight);

        setRenderer(renderer);
        setScene(scene);
        setCamera(camera);
        
        camera.position.set(0, 0, 10); // Set camera position
        camera.lookAt(new THREE.Vector3(0, 0, 0)); // Ensure camera looks at the scene

        const animate = () => {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
        };

        animate();

        // Clean up scene on component unmount
        return () => {
            renderer.dispose();
            document.getElementById('3d-graph').innerHTML = ''; // Clear the DOM
        };
    }, []);

    useEffect(() => {
        if (selectedData.length > 0 && scene && camera) {
            // Clear previous spheres
            while (scene.children.length > 2) { // Keep lights
                scene.remove(scene.children[2]);
            }

            // Create points on the graph
            selectedData.forEach(item => {
                const geometry = new THREE.SphereGeometry(0.1, 32, 32);
                const material = new THREE.MeshBasicMaterial({ color: 0x0077ff });
                const sphere = new THREE.Mesh(geometry, material);
                sphere.position.set(item[xAxis] || 0, item[yAxis] || 0, item[zAxis] || 0); // Use selected axes
                scene.add(sphere);
            });
        }
    }, [selectedData, xAxis, yAxis, zAxis, scene]);

    return (
        <div>
            <h1>3D График</h1>
            <div className="form-container">
                <label>
                    Начальная дата:
                    <input 
                        type="date" 
                        value={startDate} 
                        onChange={(e) => setStartDate(e.target.value)} 
                    />
                </label>
                <label>
                    Конечная дата:
                    <input 
                        type="date" 
                        value={endDate} 
                        onChange={(e) => setEndDate(e.target.value)} 
                    />
                </label>
                <div id="3d-graph" style={{ width: '100%', height: '500px' }}></div>
            </div>
        </div>
    );
};

export default Graph3DPage;
