// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-menu {
    background-color: #2c3e50; /* Темный фон */
    color: white; /* Белый текст */
    width: 250px;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5); /* Тень для глубины */
    transition: transform 0.3s ease; /* Плавный переход */
}

.left-menu.open {
    transform: translateX(0); /* Показываем меню */
}

.left-menu.closed {
    transform: translateX(-100%); /* Скрываем меню */
}
.left-menu a {
    color: #ecf0f1; /* Светлый цвет текста для ссылок */
    text-decoration: none; /* Убираем подчеркивание */
    display: block; /* Каждая ссылка занимает всю ширину */
    padding: 10px 15px; /* Отступы для удобства */
    border-radius: 5px; /* Закругленные углы */
    transition: background-color 0.3s; /* Плавный переход фона */
}

.left-menu a:hover {
    background-color: #34495e; /* Цвет фона при наведении */
}
.left-menu h2 {
    font-size: 24px; /* Размер шрифта заголовка */
    margin-bottom: 20px; /* Отступ снизу */
    text-align: center; /* Центрируем заголовок */
}
`, "",{"version":3,"sources":["webpack://./src/LeftMenu.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,eAAe;IAC1C,YAAY,EAAE,gBAAgB;IAC9B,YAAY;IACZ,aAAa;IACb,wCAAwC,EAAE,qBAAqB;IAC/D,+BAA+B,EAAE,oBAAoB;AACzD;;AAEA;IACI,wBAAwB,EAAE,oBAAoB;AAClD;;AAEA;IACI,4BAA4B,EAAE,kBAAkB;AACpD;AACA;IACI,cAAc,EAAE,mCAAmC;IACnD,qBAAqB,EAAE,0BAA0B;IACjD,cAAc,EAAE,sCAAsC;IACtD,kBAAkB,EAAE,yBAAyB;IAC7C,kBAAkB,EAAE,sBAAsB;IAC1C,iCAAiC,EAAE,yBAAyB;AAChE;;AAEA;IACI,yBAAyB,EAAE,4BAA4B;AAC3D;AACA;IACI,eAAe,EAAE,4BAA4B;IAC7C,mBAAmB,EAAE,iBAAiB;IACtC,kBAAkB,EAAE,yBAAyB;AACjD","sourcesContent":[".left-menu {\n    background-color: #2c3e50; /* Темный фон */\n    color: white; /* Белый текст */\n    width: 250px;\n    padding: 20px;\n    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5); /* Тень для глубины */\n    transition: transform 0.3s ease; /* Плавный переход */\n}\n\n.left-menu.open {\n    transform: translateX(0); /* Показываем меню */\n}\n\n.left-menu.closed {\n    transform: translateX(-100%); /* Скрываем меню */\n}\n.left-menu a {\n    color: #ecf0f1; /* Светлый цвет текста для ссылок */\n    text-decoration: none; /* Убираем подчеркивание */\n    display: block; /* Каждая ссылка занимает всю ширину */\n    padding: 10px 15px; /* Отступы для удобства */\n    border-radius: 5px; /* Закругленные углы */\n    transition: background-color 0.3s; /* Плавный переход фона */\n}\n\n.left-menu a:hover {\n    background-color: #34495e; /* Цвет фона при наведении */\n}\n.left-menu h2 {\n    font-size: 24px; /* Размер шрифта заголовка */\n    margin-bottom: 20px; /* Отступ снизу */\n    text-align: center; /* Центрируем заголовок */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
