import React, { useEffect, useRef } from 'react';
import axios from 'axios';

const MapPage = () => {
    const mapRef = useRef(null); // Используем useRef для хранения карты

    useEffect(() => {
        // Инициализация карты
        const initMap = () => {
            if (!mapRef.current) { 
                mapRef.current = new window.ymaps.Map('map', {
                    center: [53.630561, 55.921474], 
                    zoom: 10,
                });

                // Получение данных из базы данных
                axios.get("http://94.103.86.215:4000/database")
                    .then(response => {
                        const data = response.data;

                        data.forEach(item => {
                            const placemark = new window.ymaps.Placemark([item.width, item.longitude], {
                                balloonContent: `<strong>${item.device}</strong><br>Температура: ${item.temperature} ℃<br>Влажность: ${item.humidity} %<br>Давление: ${item.pressure} Па <br>CO2: ${item.co2} Промиль<br>Азот: ${item.azot} .<br>Освещение: ${item.light} Люмень`
                            });

                            mapRef.current.geoObjects.add(placemark);
                        });
                    })
                    .catch(error => {
                        console.error("Ошибка при получении данных:", error.message);
                    });
            }
        };

        // Загрузка API Яндекс.Карт
        window.ymaps.ready(initMap);

        // Очистка эффекта
        return () => {
            mapRef.current = null; // Удаляем ссылку на карту при размонтировании
        };
    }, []);

    return (
        <div>
            <h1>Карта с данными</h1>
            <div id="map" style={{ height: '800px' }}></div>
        </div>
    );
};

export default MapPage;