import React from 'react';
import './LeftMenu.css'; // Предполагается, что у вас есть CSS для стилей меню

const LeftMenu = ({ isOpen }) => {
    return (
        <div className={`left-menu ${isOpen ? 'open' : 'closed'}`}>
            {/* Содержимое меню */}
            <h2>Меню</h2>
            <ul>
            <li><a href="/app">Данные с газоанализатора</a></li>
                <li><a href="/map">Карта</a></li>
                <li><a href="/data-analysis">Анализ данных</a></li>
                <li><a href="/3d-graph">3D График</a></li>

            </ul>
        </div>
    );
};

export default LeftMenu;